<template>
  <b-form-row>
    <b-col>
      <h4 class="page-sub-header pl-3 pb-2">Edit sample data</h4>
      <div
        class="b-table-sticky-header custom-scroll h-auto"
        v-if="chartSampleData"
      >
        <table class="table table-responsive" ref="table">
          <tbody>
            <tr>
              <td><input type="text" class="form-control" disabled /></td>
              <td v-for="(s, sIndex) in chartSampleData.series" :key="sIndex">
                <input
                  type="text"
                  class="form-control"
                  v-model="s.label"
                  @input="onChangeSampleData"
                />
              </td>
            </tr>
            <tr
              v-for="(xLabel, xIndex) in chartSampleData.labels"
              :key="xIndex"
            >
              <td>
                <input
                  type="text"
                  class="form-control"
                  v-model="chartSampleData.labels[xIndex]"
                  @input="onChangeSampleData"
                />
              </td>
              <td v-for="(s, vIndex) in chartSampleData.series" :key="vIndex">
                <input
                  type="text"
                  class="form-control"
                  v-model.number="s.data[xIndex]"
                  @input="onChangeSampleData"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-col>
  </b-form-row>
</template>
<script>
import { mapState } from 'vuex'
import { cloneDeep } from 'lodash'
import ColumnResizer from 'column-resizer'
export default {
  name: 'CustomizeChartData',
  data () {
    return {
      columnResizer: null
    }
  },
  computed: {
    ...mapState({
      chartSampleData: (state) => state.widgets.chartSampleData
    })
  },
  beforeCreate () {
    this.$store.dispatch('widgets/defaultChartSampleData')
  },
  mounted () {
    setTimeout(() => {
      this.columnResizer = new ColumnResizer(this.$refs.table, {
        liveDrag: true,
        flush: true,
        draggingClass: 'rangeDrag',
        gripInnerHtml: "<div class='rangeGrip'></div>",
        minWidth: 8
      })
    }, 1000)
  },
  destroyed () {
    if (this.columnResizer) {
      this.columnResizer.destroy()
    }
  },
  methods: {
    onChangeSampleData () {
      this.$store.commit(
        'widgets/setChartSampleData',
        cloneDeep(this.chartSampleData)
      )
    }
  }
}
</script>
<style lang="scss" scoped>
input {
  height: 30px !important;
  border-radius: 0 !important;
  border: 0 !important;
}
.table thead th,
.table td {
  padding: 0;
  border: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
}
</style>
